import { ApiResponse } from '../models/ApiResponse';
import BaseService from './BaseService';

class VersionService extends BaseService {
  public static getBackendVersion(): Promise<ApiResponse<string>> {
    return this.get('/v1/version');
  }
}

export default VersionService;
